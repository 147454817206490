import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SimpleSection from "../components/sections/simpleSection/SimpleSection";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { HelmetDatoCms } from "gatsby-source-datocms";

const Rules = ({ data }) => {
  return (
    <>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={data.page.sectiontitle} />
        <SimpleSection
          id="clubcards"
          sectionTitle={data.page.sectiontitle}
          sectionTitleDesc={data.page.sectiondescription}
          sectiontitleBtnTxt=""
          sectiontitleBtnUrl={false}
          isBlackBg={false}
        >

          <div className="about-text-wrapper">
            <section className="html-section" style={{ padding: `34px 16px` }}>
              <div
                key={`body`}
                style={{ maxWidth: `1100px`, width: `100%` }}
                dangerouslySetInnerHTML={{ __html: data.page.htmlcontent }}
              ></div>
            </section>
          </div>
        
        </SimpleSection>
      </Layout>
    </>
  );
};

export default Rules;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsRulespage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      htmlcontent
      sectiontitle
      sectiondescription
    }
  }
`;

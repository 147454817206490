import React from 'react'
import SectionTitle from '../SectionTitle'

const SimpleSection = ({
    id,
    sectionTitle,
    sectionTitleDesc,
    sectiontitleBtnTxt,
    sectiontitleBtnUrl,
    isBlackBg,
    children,
    customClass
}) => {
    return (
        <section id={id} className={isBlackBg? "black_bg" : "light_bg"}>
            <SectionTitle 
                isBlackBg={isBlackBg}
                title={sectionTitle}
                description={sectionTitleDesc}
                url={sectiontitleBtnUrl}
                urlBtnTxt={sectiontitleBtnTxt}
            />
            <div className={ customClass ? `${customClass}  section__padding` : "section__padding"}>
                {children}
            </div>
        </section>
    )
}

export default SimpleSection

import React from "react"

const MyButton = ({ children, props, isBlackBg }) => {
    return (
      <button {...props} className={isBlackBg? "ol__btn ol__btn_white": "ol__btn ol__btn_black"}>
        <span>{children}</span>
      </button>
    )
}

export default MyButton

import React from 'react'
import {Link} from 'gatsby'
import { MdHome } from "react-icons/md";


const BreadCrumps = ({title}) => {
    return (
        <div className="section__padding breadcrumps border__bottom__light">
            <div className="breadcrumps__container">
                <div className="breadcrumps__box">
                    <Link to="/" className="breadcrump__link"><MdHome />{" "} <p style={{marginLeft: `6px`}}>ГОЛОВНА</p></Link>
                    <p>
                        <span style={{marginRight: `8px`}}>/</span>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BreadCrumps

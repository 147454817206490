import React from 'react'
import { Link } from 'gatsby'

import MyButton from "../../ui/button/MyButton"
import Arrow from "../../ui/btnArrow/Arrow"
import ButtonLinkWrapper from "../../ui/btnLinkWrapper/ButtonLinkWrapper"

const SectionTitle = ({title, description, url, urlBtnTxt , isBlackBg}) => {
    return (
        <div className={isBlackBg ? "section__container border__bottom__white" : "section__container border__bottom__light"}>
        <div className={isBlackBg ? "section__title__left__side section__side__padding border__right__white" : "section__title__left__side section__side__padding border__right__light"}>
            {
                title && <h2 className={isBlackBg ? "section__title txt__color__white": "section__title txt__color__black"}>
                {title}
            </h2>
            }
        </div>
        <div className="section__title__right__side section__side__padding">
            <div className="title__content__wrapper">
                <div style={{padding: `16px`, maxWidth: `410px`, float: `left`}}>
                    <p className={isBlackBg ? "txt__color__white": "txt__color__black"}>
                        {description}
                    </p>
                </div>
                {
                    url &&
                    <Link to={url} style={{textDecoration: "none"}}>
                        <ButtonLinkWrapper>
                            <MyButton isBlackBg={isBlackBg}>{urlBtnTxt}</MyButton>
                            <Arrow isBlackBg={isBlackBg} />
                        </ButtonLinkWrapper>
                    </Link>
                }
            </div>
        </div>
      </div>
    )
}

export default SectionTitle

import React from 'react'
import blackIcon from '../../images/icons/right-arrow_b.svg'
import whiteIcon from '../../images/icons/right-arrow_w.svg'


const Arrow = ({isBlackBg}) => {
    return (
        <div className={isBlackBg ? "btn_arrow btn_arrow_b isCentered" : "btn_arrow btn_arrow_w isCentered"}>
            {
                isBlackBg ? <div className="icon_wrraper">
                    <img src={whiteIcon} alt="arrow"/>


                </div>:
                <div className="icon_wrraper">
                    <img src={blackIcon} alt="arrow"/>
                </div>
            }
        </div>
    )
}

export default Arrow
